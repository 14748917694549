import { find, get, isNull } from 'lodash/fp';
import { Strong } from '@ahmdigital/ui';
import React from 'react';
import replacePlaceholder from '@ahmdigital/logic/lib/content/offers/shared/replace-placeholder';

import logger from '../../../../logging';
import type { Offer, OfferRewards, Placeholder } from '../../types';

type PlaceholderSerializer = {
  offer: Offer;
  offerTypeId?: string;
  placeholders: Placeholder[];
  rewards?: OfferRewards;
};

const placeholderSerializer =
  ({ offer, offerTypeId, placeholders, rewards }: PlaceholderSerializer) =>
  // @ts-ignore - Automatic, Please fix when editing this file
  (value) => {
    const matchedPlaceholder = find({ _id: get('node._ref', value) }, placeholders);
    const placeholderId = get('identifier.current', matchedPlaceholder);
    const result = replacePlaceholder({ offer, offerTypeId, placeholderId, rewards });

    if (isNull(result)) {
      logger
        .getLogger()
        .error('Failed to replace offer terms and conditions placeholder', { matchedPlaceholder, offer, value });
      return <Strong>[[PLACEHOLDER:{placeholderId}]]</Strong>;
    }
    return result;
  };

export default placeholderSerializer;
