import { isEmpty } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import getDefinitionErrors from '@ahmdigital/offers/lib/model/offer/get-validation-errors/get-definition-errors';

import logger from '../../../../logging';

// @ts-ignore - Automatic, Please fix when editing this file
const validateOffer = (offer) => {
  const errors = getDefinitionErrors(offer);
  if (!isEmpty(errors)) {
    logger.getLogger().error('Offer validation error', { errors, offer });
  }
};

export default validateOffer;
