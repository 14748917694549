import { Col, Row } from '@ahmdigital/ui';
import { compact, includes } from 'lodash/fp';
import joinPortableText from '@ahmdigital/logic/lib/content/utils/join-portable-text';
import React, { ComponentProps } from 'react';

import { VARIANT } from '../../constants';
import Content from '../content';
import getCodeType from '../../../logic/get-code-type';
import getContentForOffer, { Subtext } from '../../utils/get-content-for-offer';
import OfferImage from './components/offer-image';
import PromoCode from '../promo-code';
import styles from './styles.module.sass';
import TileWrapper from '../tile-wrapper';
import type { Offer } from '../../../types';

type SingleOfferDisplayProps = Pick<ComponentProps<typeof TileWrapper>, 'isBoxed' | 'variant'> &
  Pick<
    ComponentProps<typeof Content>,
    | 'codeType'
    | 'placeholders'
    | 'promoCode'
    | 'promoCodeDescription'
    | 'referralUrl'
    | 'showCodeCopy'
    | 'showFindCover'
    | 'showMemberLogin'
  > & {
    offer: Offer;
    referralCode: string;
  };

const SingleOfferDisplay = ({
  variant,
  isBoxed,
  referralCode,
  offer,
  placeholders,
  showMemberLogin,
  showCodeCopy,
  referralUrl,
  showFindCover,
}: SingleOfferDisplayProps) => {
  const { ends, header, image, promoCode, promoCodeDescription, subtext, subtextPrefix } = getContentForOffer(
    offer,
    referralCode,
  );
  const isHeaderVariant = variant === VARIANT.HEADER;
  const isStripVariant = variant === VARIANT.STRIP;

  const codeType = getCodeType(offer);

  const portableText = compact([subtextPrefix && { value: subtextPrefix }, subtext]);

  const prefixedSubText = variant === VARIANT.HEADER ? joinPortableText(portableText as any) : subtext;

  const contentComponent = (
    // @ts-ignore - Automatic, Please fix when editing this file
    <Content
      {...{
        codeType,
        ends,
        header,
        offer,
        placeholders,
        promoCode,
        promoCodeDescription,
        referralUrl,
        showCodeCopy,
        showFindCover,
        showMemberLogin,
        subtext: prefixedSubText as Subtext,
      }}
    />
  );

  return (
    <TileWrapper variant={variant} isBoxed={isBoxed}>
      <Row>
        <Col df={isStripVariant ? 10 : 12} dfOffset={isStripVariant ? 1 : 0} sm={12} smOffset={0}>
          <Row>
            {includes(variant, [VARIANT.HEADER, VARIANT.TILE]) && (
              <>
                {/* @ts-ignore - Automatic, Please fix when editing this file */}
                <OfferImage image={image} className={isHeaderVariant && styles.hideDesktop} />
                {contentComponent}
                {/* @ts-ignore - Automatic, Please fix when editing this file */}
                {isHeaderVariant && <OfferImage image={image} className={styles.hideTabletAndMobile} />}
              </>
            )}
            {isStripVariant && (
              <>
                {contentComponent}
                <Col df={4} dfOffset={1} md={12} mdOffset={0}>
                  <PromoCode
                    showMemberLogin={showMemberLogin}
                    showCodeCopy={showCodeCopy}
                    // @ts-ignore - Automatic, Please fix when editing this file
                    promoCode={promoCode}
                    referralUrl={referralUrl}
                    // @ts-ignore - Automatic, Please fix when editing this file
                    promoCodeDescription={promoCodeDescription}
                    codeType={codeType}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </TileWrapper>
  );
};

export default SingleOfferDisplay;
