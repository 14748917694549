import { Button, H2, Image, PortableText, Section, Well } from '@ahmdigital/ui';
import { isEmpty, isNil, toLower } from 'lodash/fp';
import generatePortableText from '@ahmdigital/logic/lib/content/utils/generate-portable-text';
// @ts-ignore - Automatic, Please fix when editing this file
import getAllRewards from '@ahmdigital/offers/lib/model/offer/get-all-rewards';
import React from 'react';
import styled from 'styled-components';

import getCodeType from '../../../logic/get-code-type';
import getContentForOffer from '../../utils/get-content-for-offer';
import getFindCoverCtaContent from '../../utils/get-find-cover-cta-content';
import getSubtextValue from '../../utils/get-subtext-value';
import OfferHeading from '../offer-heading';
import offerTagImage from '../../../images/offer-tag.svg';
import PromoCode from '../promo-code';
import useGetAllPlaceholders from '../../../logic/use-get-all-placeholders';
import type { Offer, Placeholder } from '../../../types';

type OfferWellProps = {
  offer?: Offer;
  placeholders?: Placeholder[];
  referralCode: string;
  referralUrl?: string;
  showFindCover?: boolean;
};

const restrictedImageHeight = '15.625rem';
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${restrictedImageHeight};
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
`;

const StyledImage = styled(Image)`
  max-height: ${restrictedImageHeight};
`;

const LogoContainer = styled.div`
  padding-bottom: 1rem;
`;

const BodyContainer = styled.div`
  padding-bottom: 1rem;
`;

const WellContainer = styled(Well)`
  background: white;
  height: 100%;
  box-shadow: 0px 4px 7px rgba(128, 128, 128, 0.2);
  border-radius: 8px;
`;

const CtaButton = styled(Button)`
  width: 100%;
`;

const StyledSection = styled(Section)`
  padding: 0;
`;

const OfferWell = ({
  offer,
  placeholders: overridePlaceholders,
  referralCode,
  referralUrl,
  showFindCover,
}: OfferWellProps) => {
  const { placeholders: fetchedPlaceholders } = useGetAllPlaceholders({ skip: !isEmpty(overridePlaceholders) });
  const placeholders = !isEmpty(overridePlaceholders) ? overridePlaceholders : fetchedPlaceholders;

  if (isNil(offer)) {
    return null;
  }

  const { ends, header, image, promoCode, promoCodeDescription, subtext } = getContentForOffer(offer, referralCode);
  const codeType = getCodeType(offer);
  const rewards = getAllRewards(offer);

  const ctaContent = getFindCoverCtaContent(offer);
  return (
    // Added id to enable scrolling to specific offer - mostly a problem when on mobile as the offers become stacked.
    // @ts-ignore - Automatic, Please fix when editing this file
    <StyledSection id={toLower(promoCode)}>
      <WellContainer>
        <BodyContainer>
          <ImageContainer>{image && <StyledImage src={image.src} alt={image.alt} />}</ImageContainer>
          <LogoContainer>
            <Image src={offerTagImage.src} alt="Offer" />
          </LogoContainer>
          <H2 variant="marginBottomSpacer11" looksLike="h4">
            <OfferHeading offer={offer} placeholders={placeholders} rewards={rewards} header={header} />
          </H2>
          <PortableText source={generatePortableText(getSubtextValue(subtext, ends))} />
        </BodyContainer>
        <PromoCode
          codeType={codeType}
          displayFullWidth
          // @ts-ignore - Automatic, Please fix when editing this file
          promoCode={promoCode}
          // @ts-ignore - Automatic, Please fix when editing this file
          promoCodeDescription={promoCodeDescription}
          referralUrl={referralUrl}
          showCodeCopy
          showMemberLogin={false}
        />
        {showFindCover && (
          <CtaButton variant="flair" analytics={{ label: 'Header Show Products' }} to={ctaContent.url}>
            {ctaContent.name}
          </CtaButton>
        )}
      </WellContainer>
    </StyledSection>
  );
};

OfferWell.defaultProps = {
  offer: null,
  referralUrl: null,
  showFindCover: false,
};

export default OfferWell;
