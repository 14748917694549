import { concat, flatten, get } from 'lodash/fp';

// @ts-ignore - Automatic, Please fix when editing this file
const getSubtextValue = (subtext, ends) => {
  const endsValue = get('value', ends);

  if (!endsValue) return subtext;
  const spacer = { type: 'span', value: ' ' };

  return {
    ...subtext,
    value: concat(subtext.value, endsValue && flatten([spacer, endsValue])),
  };
};

export default getSubtextValue;
