import sanityImageUrlBuilder from '@ahmdigital/logic/lib/utils/sanity-image-url-builder';

import client from '../../client';

/**
 * @param {object} source - Object from Sanity that contains information about the image.
 * @returns {object} Object that can be called with many functions to override behaviour.
 * To override sizing while preserving aspect ratio you can set either height or width to undefined.
 * If you currently have SVG sources, upload a jpg, or just set `.format('jpg')` to test your sizing is reasonable.
 * NOTE: for more detail on the usage of the imageUrlBuilder see https://www.sanity.io/docs/image-url.
 */
// @ts-ignore - Automatic, Please fix when editing this file
const imageUrlBuilder = (source) =>
  sanityImageUrlBuilder({ dataset: client.dataset, projectId: client.projectId })(source);

export default imageUrlBuilder;
