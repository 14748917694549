import { filter, includes } from 'lodash/fp';
// @ts-ignore - Automatic, Please fix when editing this file
import findActiveByTargetSegment from '@ahmdigital/offers/lib/model/offers/find-active-by-target-segment';
import moment from 'moment';

import { NOW } from '../../../../cms/constants';
import { SWAG_OFFER_IDS, SWAG_SOURCE_CODE } from '../../../../ahm-constants/ui';
import client from '../../../../cms/client';
import constants from '../../../../ahm-constants';
import useGetAllOffers from '../use-get-all-offers';

// @ts-ignore - Automatic, Please fix when editing this file
const useGetActivePromoOffer = ({ referrerAttribution = null, targetSegment, skip = false } = {}) => {
  const isSwagReferrer = referrerAttribution === SWAG_SOURCE_CODE;

  const { useLiveFiltering, asAtDate, asAtTime } = client;
  const effectiveDate =
    // @ts-ignore - Automatic, Please fix when editing this file
    useLiveFiltering && asAtDate && asAtDate !== NOW
      ? moment(`${asAtDate} ${asAtTime}`, `${constants.DATE_FORMAT.STANDARD} HH:mm`)
      : moment();

  const { allOffers, loading } = useGetAllOffers({ skip });

  // Do this last to prevent a rule-of-hooks lint failure
  if (skip) {
    return { activeOffers: null, loading: false };
  }

  if (isSwagReferrer) {
    const swagOffers = filter((offer) => includes(offer?.identifier?.current, SWAG_OFFER_IDS), allOffers);
    return { activeOffers: swagOffers, loading };
  }

  // Only returns only last three active offers as we only want to display the last three that are active and this reduces additional filtering later on.
  const activeOffers = findActiveByTargetSegment(allOffers, targetSegment, {
    effectiveDate: effectiveDate.toDate(),
  }).slice(-3);

  return { activeOffers, loading };
};

export default useGetActivePromoOffer;
